/*
 * features.less
 * -----------------------------------------------
*/

.feature-item {
  overflow: hidden;
}
.feature-item .thumb {
  overflow: hidden;
  position: relative;
}
.features-title {
  border: 1px solid #eee;
  padding: 15px 15px;
}
.features-icon i {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #aaa;
  border-radius: 3px;
  color: #111;
  float: left;
  font-size: 28px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
  margin-top: -5px;
  text-align: center;
  width: 45px;
}
.features-title h3 {
  font-size: 22px;
  margin: 0;
}
.feature-item .features-description {
  height: 100%;
  opacity: 0;
  padding: 0 30px 30px;
  position: absolute;
  top: 0;
  transition: all 300ms ease-in-out 0s;
}
.feature-item:hover .thumb .features-description {
  opacity: 1;
  padding: 25px 30px 30px;
}
.feature-item .thumb img {
  transition: all 300ms ease-in-out 0s;
}
.feature-item:hover .thumb img {
  transform: scale3d(1.1, 1.1, 1.1);
}
/* Large Devices, Wide Screens */
@media only screen and (max-width : 1199px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {
}
@media only screen and (max-width : 479px) {
}
.features-icon-box {
  position: relative;
  transition: all 300ms ease-in-out 0s;
}
.features-icon-box .features-box-number {
  font-size: 48px;
  font-weight: 600;
  left: 24px;
  opacity: 0.06;
  position: absolute;
  top: 0;
  transition: all 300ms ease-in-out 0s;
}
.features-icon-box:hover .features-box-number {
  font-size: 64px;
}

.featured-blog {
	overflow: hidden;
	position: relative;
}
.featured-blog-details {
	bottom: -18%;
	padding: 30px;
	position: absolute;
	transition: all 400ms ease-in-out 0s;
	width: 100%;
}
.featured-blog:hover .featured-blog-details {
  bottom: 0;
}
.reply-box .form-group input {
	border-radius: 0;
	height: 45px;
	margin-bottom: 30px;
}

.featured-blog {
	overflow: hidden;
	position: relative;
}
.featured-blog-details {
	bottom: -18%;
	padding: 30px;
	position: absolute;
	transition: all 400ms ease-in-out 0s;
	width: 100%;
}
.featured-blog:hover .featured-blog-details {
  bottom: 0;
}