/*
 * widgets.less
 * -----------------------------------------------
*/
.widget.dark .tags a {
  border: 1px solid @black-444;
}
.sidebar.sidebar-left .widget .widget-title {
	background: #f7f7f7 none repeat scroll 0 0;
	font-family: "Roboto", sans-serif;
	font-size: 17px;
	font-weight: 500;
	margin-bottom: 20px;
	margin-top: 0;
	padding: 5px 15px 8px 16px;
	position: relative;
}
.sidebar.sidebar-left .widget .widget-title::after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}
.sidebar.sidebar-left .widget .widget-title.title-dots.small {
	padding: 0;
	background: url("../images/title-dots.png") repeat scroll 0 0;
}