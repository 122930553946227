/*
 * blog.less
 * -----------------------------------------------
*/
.blog-posts .post .entry-content {
  border: 1px solid transparent;
  position: relative;
}
.post .post-thumb span {
  bottom: 12px;
  color: #fff;
  font-size: 12px;
  padding: 0 8px;
  position: absolute;
  right: 12px;
}