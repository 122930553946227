/*
 * team.less
 * -----------------------------------------------
*/
.single-member .team-thumb {
  overflow: hidden;
  position: relative;
}
.single-member .team-thumb h4 {
  bottom: 0;
  padding: 10px;
  position: absolute;
  right: -99%;
  transition: all 300ms ease-in-out 0s;
  width: 100%;
}
.single-member:hover .team-thumb h4 {
  right: 0;
}
.image-box-thumb {
  overflow: hidden;
  position: relative;
}
.team-social-icon {
  bottom: -50px;
  left: 15px;
  opacity: 0;
  position: absolute;
  transition: all 300ms ease-in-out 0s;
}
.image-box-thumb:hover .team-social-icon {
  bottom: 2px;
  opacity: 1;
}
.image-box-thumb .team-social-icon li a:hover i {
	color: @white-base;
}
.team-social-icon-hover li a:hover i {
  color: @white-base;
}