@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        eConsulting
Version:        2.0
Created Date:   10.05.2017
Primary use:    Consulting Finance Corporate Business - eConsulting
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-econsulting/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
@import "../css/elegant-icons.css";
@import "../fonts/icomoon/style.css";
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/utility-classes.css";
//@import "../css/flaticon-set-law.css";
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700|Roboto:100,300,400,500,700,900');

// Initialize Variables
@import "less-econsulting/variables.less";
@import "less-econsulting/mixins.less";

// Typography
@import "less-econsulting/typography.less";

// Common Styles
@import "less-econsulting/common.less";
@import "less-econsulting/extra.less";
@import "less-econsulting/overlay.less";

// Header
@import "less-econsulting/header.less";

// Nav
@import "less-econsulting/nav.less";

// Content Blocks
@import "less-econsulting/topbar.less";
@import "less-econsulting/inner-header-title.less";
@import "less-econsulting/vertical-nav.less";
@import "less-econsulting/menu-full-page.less";
@import "less-econsulting/boxed-layout.less";
@import "less-econsulting/form.less";
@import "less-econsulting/side-push-panel.less";
@import "less-econsulting/box-hover-effect.less";
@import "less-econsulting/gallery-isotope.less";
@import "less-econsulting/home.less";
@import "less-econsulting/contact.less";
@import "less-econsulting/job.less";
@import "less-econsulting/event.less";
@import "less-econsulting/shop.less";
@import "less-econsulting/blog.less";

// Shortcodes
@import "less-econsulting/shortcodes.less";


// Widgets
@import "less-econsulting/widgets.less";



// Widgets
@import "less-econsulting/custom-theme/loader.less";

// Footer
@import "less-econsulting/footer.less";