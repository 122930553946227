/*
 * nav.less
 * -----------------------------------------------
*/
.menuzord-menu > li > a {
  color: #404040;
  font-family: @font-roboto-slab;
  font-size: 14px;
}
.menuzord-menu > li {
  padding: 24px 0;
}
/* Extra Small Devices, Phones */
@media only screen and (max-width : 900px) {
	.menuzord.small-padding .menuzord-menu > li {
	  padding: 0;
	}
}
.menuzord.small-padding .menuzord-menu > li {
  padding: 18px 0;
}
.menuzord.small-padding .menuzord-menu ul.dropdown {
  top: 71px;
}